import axios from '@axios'
import config from '../../../../config'

const prefix = config.serviceUrl

/**
 * 获取上传列表
 * @param {page} 页码
 * @param {pageSize} 每页条数
 * @param {channelId} 频道ID
 * @param {channelName} 频道名
 * @param {status} 上传状态
 * @param {title} 标题名
 * @param {cpCreatAtStart} 开始时间
 * @param {cpCreatAtEnd} 结束时间
 */

export const getPage = params => axios.get(`${prefix}/videoUpload/page`, { params })

/**
 * 获取频道列表
 */
export const getChannelList = () => axios.get(`${prefix}/channel/channelList`)

/**
 * 上传视频
 * @param {params} channelId 频道ID
 * @param {params} title 标题
 * @param {params} desc 说明
 *
 */
export const uploadVideo = params => axios.post(`${prefix}/videoUpload/saveVideo`, params)

/**
 * 编辑视频上传
 */
export const uploadVideoEdit = params => axios.put(`${prefix}/videoUpload/updateVideo`, params)

/**
 * 编辑视频发布
 */
export const videoAsperaUpload = params => axios.get(`${prefix}/videoUpload/videoAsperaUpload/${params.id}`)

/**
 * 更新数据
 * @returns {Promise<AxiosResponse<any>>}
 */
export function updateDataPlay(params) {
  return axios.get(`${prefix}/youtube/data/syncPlaylist`, { params })
}

/**
 * 获取播放列表
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getPlayList(params) {
  return axios.get(`${prefix}/youtube/data/playList`, { params })
}

/**
 * 获取字幕语言列表
 */
export const getSubtitleLanguageList = params => axios.get(`${prefix}/videoUpload/asperaField/${params.type}`)

/**
 * 视频上传重传
 * @param {id} 列表id
 * @returns
 */
export function retryUpload(id) {
  return axios.get(`${prefix}/videoUpload/videoAsperaUpload/${id}`)
}
