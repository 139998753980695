<script>
import {
  ref, computed, watch, onMounted,
} from '@vue/composition-api'
import { emailValidator, required } from '@core/utils/validation'
import { mdiClose } from '@mdi/js'
import {
  modalFail, toastSuccess, modalEnquireTitle, toastInfo, toastFail,
} from '@core/utils/prompt'
import { getVideoData } from '@core/utils/dataProcess'
import FileInputSingle from '@/views/components/file-input/FileInputSingle.vue'
import {
  getChannelList, uploadVideo, uploadVideoEdit, videoAsperaUpload, updateDataPlay, getPlayList, getSubtitleLanguageList,
} from './service'
import config from '../../../../config'

export default {
  components: {
    FileInputSingle,
  },
  props: {
    isAddNewSidebarActive: {
      type: Boolean,
      required: true,
    },
    currItem: {
      type: Object,
      default: () => {},
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const playList = ref([])
    const {
      aliBucket = config.defaultBucket,
      channelId = null,
      channelName = null,
      content = null,
      fileName = '',
      videoPath = '',
      duration = null,
      imgPath = '',
      imgName = '',
      audience = 'no',
      subtitleLanguage = null,
      subtitlePath = '',
      subtitleName = '',
      range = '私享',
      playlistId = null,
      title = null,
    } = props.currItem

    const blankData = {
      aliBucket,
      channelId,
      channelName,
      content,
      fileName,
      videoPath,
      duration,
      title,
      subtitlePath,
      imgPath,
      imgName,
      subtitleLanguage,
      subtitleName,
      audience,
      range,
      playlistId: playlistId ? playlistId.split('|') : [],
    }

    const submitData = ref(JSON.parse(JSON.stringify(blankData)))

    const selectRange = [
      { label: '私享', value: '私享' },
      { label: '不公开列出', value: '不公开列出' },
      { label: '公开', value: '公开' },
    ]
    const menu1 = ref([false, false]) // 时间选择器
    const releaseAtProps = props.currItem?.releaseAt?.split(' ') || [null, null]
    const releaseAtDate = ref(releaseAtProps[0] || '')
    const releaseAtTime = ref(releaseAtProps[1] || '')
    const showReleaaseAtTime = ref(false)
    const releaseAt = computed({
      get() {
        return releaseAtDate.value && releaseAtTime.value ? `${releaseAtDate.value} ${releaseAtTime.value}` : ''
      },
      set(val) {
        [releaseAtDate.value, releaseAtTime.value] = val.split(' ')
      },
    })

    const channelList = ref([])

    const valid = ref(false)
    const form = ref(null)

    const fileNameSubtitle = ref('')
    const fileDetailVideo = ref({})
    const fieldVideoDuration = ref(0)
    const fileDetailThumbnail = ref({})
    const fileDetailSubtitle = ref({})
    const subtitleLanguageList = ref([])
    const showVideoUpload = ref(false)

    // 设置文件时长
    function setFileDetail(fileInfo) {
      // 当只有一个视频时
      getVideoData(fileInfo).then(data => {
        fieldVideoDuration.value = data.duration
      })
    }

    const fetchChannleList = async () => {
      const { data: { status, message, data } } = await getChannelList()

      if (status === 200) {
        channelList.value = data
      } else {
        modalFail(message)
      }
    }

    // 获取下拉表单信息  字幕语言
    const fetchSubtitleLanguageList = async () => {
      try {
        const { data: { status, message, data } } = await getSubtitleLanguageList({ type: 'subtitleLanguage' })
        if (status === 200) {
          subtitleLanguageList.value = data
        } else {
          modalFail(message)
        }
      } catch (err) {
        modalFail(err.response.data.message)
      }
    }

    const validate = () => {
      form.value.validate()
    }
    const resetForm = () => {
      form.value.reset()
    }
    const getAllowDateTimes = () => {
      const thirtyMinutesLate = new Date(new Date().getTime() + 30 * 60 * 1000)

      return thirtyMinutesLate
    }
    const resetData = () => {
      submitData.value = JSON.parse(JSON.stringify(blankData))
      resetForm()
      emit('update:is-add-new-sidebar-active', false)
    }

    watch(releaseAtDate, (val, oldVal) => {
      if (val !== oldVal) {
        releaseAtTime.value = null
        showReleaaseAtTime.value = false

        setTimeout(() => {
          showReleaaseAtTime.value = true
        }, 500)
      }
    })

    // 保存视频
    const saveVideo = params => {
      if (params.id) {
        // 编辑视频
        return uploadVideoEdit(params)
      }

      const { id, status, ...otherParams } = params

      // 新增视频
      return uploadVideo(otherParams)
    }

    const getParams = () => {
      const params = {
        ...submitData.value,
        id: props.currItem.id,
        status: props.currItem.status,
        channelName: channelList.value.find(x => x.pageId === submitData.value.channelId)?.title || '',
        playlistId: submitData.value.playlistId.join('|'),
        duration: fieldVideoDuration.value,
        releaseAt: releaseAt.value,
      }

      return params
    }

    const submitSuccess = () => {
      emit('success')
      emit('update:is-add-new-sidebar-active', false)
    }

    // 保存

    const onSubmit = async () => {
      const isFormValid = form.value.validate()
      if (!isFormValid) {
        return
      }
      try {
        const { data: { status, message } } = await saveVideo(getParams())
        if (status === 200) {
          toastSuccess('保存成功！')
          submitSuccess()
        } else {
          modalFail(message)
        }
      } catch (err) {
        modalFail(err.response.data.message)
      }
    }

    // 保存并发布
    const onSubmitPublish = async () => {
      try {
        const { data: { status, message, data } } = await saveVideo(getParams())

        if (status === 200) {
          const res = await videoAsperaUpload({
            id: data || props.currItem.id,
          })
          if (res.data.status === 200) {
            toastSuccess('发布成功！')
            submitSuccess()
          }
        } else {
          modalFail(message)
        }
      } catch (err) {
        modalFail(err.response.data.message)
      }
    }

    // 发布，需要先获取保存后的id

    const handlePublish = async () => {
      const isFormValid = form.value.validate()
      if (!isFormValid) {
        return
      }
      const { isConfirmed } = await modalEnquireTitle('确认发布该视频？')

      if (isConfirmed) {
        onSubmitPublish()
      }
    }

    // 获取播放列表
    // function getPlay() {
    //   if (!submitData.value.channelId) return false
    //   getPlayList({ channelId: submitData.value.channelId }).then(res => {
    //     playList.value = res.data.data || []
    //   })

    //   return true
    // }

    // 更新播放列表
    const handleUpdatePalyList = () => {
      modalEnquireTitle('请确认要在线获取，并更新该频道的播放列表').then(result => {
        if (result.isConfirmed) {
          toastInfo('获取更新中')
          updateDataPlay({ channelId: submitData.value.channelId }).then(res => {
            if (res.data.status === 200) {
              toastSuccess('更新成功')

              // getPlay()
            }
          }).catch(e => {
            modalFail(e.response.data.message)
          })
        }
      })
    }

    onMounted(() => {
      fetchChannleList()

      // getPlay()
      fetchSubtitleLanguageList()
    })

    function containsEmojiAndSpecialChars(str) {
      const specialCharPattern = /[……_]/g

      return specialCharPattern.test(str)
    }

    const handleChangeVideo = (file = null) => {
      const { name = '' } = file || {}
      if (containsEmojiAndSpecialChars(name)) {
        toastFail('上传文件名不能含有特殊字符')
        showVideoUpload.value = false

        return false
      }
      if (file) {
        showVideoUpload.value = true
        setFileDetail(file)

        return false
      }

      return false
    }

    const changeChannel = () => {
      // getPlay()
    }

    return {
      playList,
      onSubmit,
      validate,
      resetData,
      form,
      valid,
      submitData,
      fileNameSubtitle,
      fileDetailVideo,
      fileDetailThumbnail,
      fileDetailSubtitle,
      channelList,
      subtitleLanguageList,
      selectRange,
      menu1,
      releaseAtDate,
      releaseAtTime,
      getAllowDateTimes,
      showReleaaseAtTime,

      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
      },
      handlePublish,
      handleUpdatePalyList,
      handleChangeVideo,
      changeChannel,
      showVideoUpload,
    }
  },
}
</script>
<template>
  <v-navigation-drawer
    :value="isAddNewSidebarActive"
    temporary
    touchless
    permanent
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 650 : '100%'"
    app
    @input="(val) => $emit('update:is-add-new-sidebar-active', val)"
  >
    <div height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">视频上传</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-add-new-sidebar-active',false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          :disabled="!canEdit"
          @submit.prevent="onSubmit"
        >
          <div class="form-item">
            <label class="form-item-label">频道名</label>
            <v-autocomplete
              v-model="submitData.channelId"
              :rules="[validators.required]"
              :items="channelList"
              outlined
              dense
              single-line
              clearable
              label="频道名"
              item-text="title"
              placeholder="频道名"
              hide-details="auto"
              item-value="pageId"
              no-data-text="暂无频道"
              class="mb-6"
              @change="changeChannel"
            ></v-autocomplete>
          </div>

          <div class="form-item">
            <label class="form-item-label">标题名</label>
            <v-text-field
              v-model.trim="submitData.title"
              :rules="[validators.required]"
              outlined
              dense
              clearable
              single-line
              label="标题名"
              placeholder="标题名"
              hide-details="auto"
              class="mb-6"
              maxlength="100"
              counter
            ></v-text-field>
          </div>

          <div class="form-item">
            <label class="form-item-label">说明</label>
            <div style="line-height: 20px">
              若当前视频的说明与YT后台默认上传设置内的说明内容一致，则无需填写，系统会默认使用该说明；若不一致，则请填写
            </div>
            <v-textarea
              v-model.trim="submitData.content"
              outlined
              dense
              clearable
              single-line
              counter
              maxlength="5000"
              label="说明"
              placeholder="说明"
              hide-details="auto"
              class="mb-6"
            >
            </v-textarea>
          </div>

          <div class="form-item">
            <file-input-single
              :file-input-model.sync="submitData.videoPath"
              :file-name.sync="submitData.fileName"
              :file-detail.sync="fileDetailVideo"
              :is-disabled="!canEdit"
              :bucket.sync="submitData.aliBucket"
              accept-type=".mov,.mpeg-1,.mpeg-2,.mpeg4,.mp4,.mpg,.avi,.wmv,.mpegps,.flv,.3GPP,.WebM,.DNxHR,.ProRes,.CineForm,.HEVC"
              title="上传视频"
              class="mb-4"
              :show-video-upload="showVideoUpload"
              @changeFile="handleChangeVideo"
            ></file-input-single>
          </div>

          <div class="form-item">
            <file-input-single
              :file-input-model.sync="submitData.imgPath"
              :file-name.sync="submitData.imgName"
              :file-detail.sync="fileDetailThumbnail"
              :bucket.sync="submitData.aliBucket"
              :is-required="false"
              :is-disabled="!canEdit"
              accept-type=".jpg,.jpeg,.png"
              title="上传缩略图"
              class="mb-4"
            ></file-input-single>
          </div>

          <div class="form-item">
            <file-input-single
              :file-input-model.sync="submitData.subtitlePath"
              :file-name.sync="submitData.subtitleName"
              :file-detail.sync="fileDetailSubtitle"
              :bucket.sync="submitData.aliBucket"
              :is-required="false"
              :is-disabled="!canEdit"
              accept-type=".srt"
              title="上传字幕文件"
              class="mb-4"
            ></file-input-single>
          </div>

          <div
            v-if="submitData.subtitlePath"
            class="form-item"
          >
            <label class="form-item-label">字幕语言</label>
            <div class="pt8">
              仅当有字幕文件时才需填写；如果字幕是嵌入到视频里的，则无需填写
            </div>
            <v-autocomplete
              v-model="submitData.subtitleLanguage"
              :items="subtitleLanguageList"
              :rules="[validators.required]"
              class="mt16 mb-4"
              clearable
              dense
              hide-details
              item-text="value"
              item-value="key"
              outlined
              placeholder="语言"
            />
          </div>

          <!-- <div class="form-item">
            <label class="form-item-label">播放列表<a
              v-if="canEdit"
              style="font-size: 14px"
              class="ml-4"
              @click="handleUpdatePalyList"
            >获取更新</a></label>
            <v-autocomplete
              v-model="submitData.playlistId"
              :items="playList"
              class="mt16 mb-4"
              clearable
              dense
              hide-details
              item-text="value"
              item-value="key"
              outlined
              placeholder="播放列表"
            />
          </div> -->

          <div class="form-item mb-4">
            <label class="form-item-label">观众</label>
            <div class="pt8">
              此视频是面向儿童的内容吗
            </div>
            <v-radio-group
              v-model="submitData.audience"
              :mandatory="true"
              dense
              hide-details
              row
              style="margin-top: 4px"
            >
              <v-radio
                label="是，内容是面向儿童的"
                value="yes"
              ></v-radio>
              <v-radio
                label="否，内容不是面向儿童的"
                value="no"
              ></v-radio>
            </v-radio-group>
          </div>

          <div class="form-item mt-4">
            <label class="form-item-label">公开范围</label>
            <v-radio-group
              v-model="submitData.range"
              hide-details
              row
              style="margin-top: 0"
            >
              <v-radio
                v-for="item in selectRange"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </v-radio-group>
          </div>

          <div
            v-if="submitData.range === '私享'"
            class="form-item mt-4 mb-4"
          >
            <label class="form-item-label">安排时间</label>
            <div class="pt8">
              选择要将您的视频设为发布的日期，必须要大于>=当前时间+30分钟 （北京时间）
            </div>
            <div
              class="mt-4 d-inline-flex"
              style="width: 500px; display: flex; align-items: start"
            >
              <v-menu
                v-model="menu1[0]"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                style="width: 200px"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="releaseAtDate"
                    :rules="[releaseAtDate ? (releaseAtTime ? true : '请再选择安排时间') : true]"
                    readonly
                    placeholder="安排日期"
                    dense
                    outlined
                    clearable
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>

                <v-date-picker
                  v-model="releaseAtDate"
                  :allowed-dates="date => (new Date(date) >= new Date() - 24*60*60*1000)"
                  @input="() => {menu1[0] = false;}"
                />
              </v-menu>
              <div
                v-if="releaseAtDate"
                class="px5"
                style="display: flex; align-items: center; justify-content: center; margin: 0 10px;  height: 40px;"
              >
                -
              </div>
              <v-menu
                v-if="releaseAtDate"
                v-model="menu1[1]"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="releaseAtTime"
                    dense
                    outlined
                    placeholder="安排时间"
                    readonly
                    clearable
                    :rules="[validators.required, () => (new Date(`${releaseAtDate} ${releaseAtTime}`).getTime()) > getAllowDateTimes() ? true: '必须要大于当前时间+30分钟']"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-time-picker
                  v-model="releaseAtTime"
                  format="24hr"
                  scrollable
                  use-seconds
                  @input="menu1[1] = false"
                ></v-time-picker>
              </v-menu>
            </div>
          </div>
        </v-form>
      </v-card-text>
    </div>
    <template #append>
      <div
        class="mt16"
        style="display: flex; justify-content: flex-end; gap: 12px; padding: 20px 10px;"
      >
        <v-btn
          color="secondary"
          outlined
          type="reset"
          :disabled="false"
          @click="resetData"
        >
          取消
        </v-btn>
        <v-btn
          color="primary"
          outlined
          :disabled="[4,7,5].includes(currItem.status)"
          @click="onSubmit"
        >
          保存
        </v-btn>
        <v-btn
          color="primary"
          class="me-3"
          :disabled="[4,7,5].includes(currItem.status)"
          @click="handlePublish"
        >
          发布
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>
<style lang="scss" scoped>
.form-item {
  .form-item-label{
    font-weight: bold;
    font-size: 16px;
  }
}
</style>
