export default [
  {
    text: '频道ID',
    value: 'channelId',
  },
  {
    text: '频道名',
    value: 'channelName',
  },
  {
    text: '视频',
    value: 'video',
    slots: 'video',
  },
  {
    text: '时间',
    value: 'time',
    slots: 'time',
    width: 250,
  },
  {
    text: '上传状态',
    value: 'status',
    align: 'center',
    slots: 'status',
  },
  {
    text: '错误信息',
    value: 'failReason',
  },
  {
    text: '操作',
    value: 'actions',
    slots: 'actions',
    align: 'center',
  },
]
