<script>
import {
  computed, defineComponent, reactive, ref,
} from '@vue/composition-api'
import {
  resolveTime,
} from '@core/utils/resolve'
import {
  modalFail, toastSuccess, modalEnquireTitle, toastFail,
} from '@core/utils/prompt'
import { AliOSS } from '@core/utils/useAliOSS'
import tableCols from './tableCols.js'
import Detail from './Detail.vue'
import { getPage, retryUpload } from './service'
import defo from '@/assets/images/defo.png'

export default defineComponent({
  components: { Detail },
  setup() {
    const loading = ref(false)
    const pageLength = ref(0)
    const page = ref(1)
    const pageSize = ref(10)
    const showDetail = ref(false)
    const currItem = ref({})
    const pageData = ref([])
    const aliOssClientMap = {}

    const dateGen = dateObj => `${dateObj.getFullYear()}-${(dateObj.getMonth() + 1).toString().padStart(2, '0')}-${dateObj.getDate().toString().padStart(2, '0')}`
    const oneMonth = 30 * 24 * 60 * 60 * 1000
    const paramsQuery = reactive({
      channelId: null,
      channelName: null,
      status: null,
      title: null,
      cpCreatAtStart: dateGen(new Date(new Date() - oneMonth)),
      cpCreatAtEnd: dateGen(new Date()),
    })

    const statusOptions = ref([
      { label: '未上传', value: 0 },
      { label: '已保存', value: 1 },
      { label: '上传中', value: 4 },
      { label: '上传失败', value: 6 },
      { label: '上传成功', value: 5 },
      { label: '下载排队', value: 7 },
    ])

    const searchConfig = computed(() => [
      {
        element: 'VTextField', props: 'channelId', label: '频道ID', placeholder: '频道ID',
      },
      {
        element: 'VTextField', props: 'channelName', label: '频道名', placeholder: '频道名称',
      },
      {
        element: 'VTextField', props: 'title', label: '标题名', placeholder: '标题名',
      },
      {
        element: 'VAutocomplete', props: 'status', placeholder: '上传状态', label: '上传状态', items: statusOptions.value.filter(x => x.value !== 0), itemText: 'label', itemValue: 'value', clearable: false,
      },
      {
        element: 'DateRangePickers', props: 'startTime', searchKey: ['cpCreatAtStart', 'cpCreatAtEnd'], initValue: [dateGen(new Date(new Date() - oneMonth)), dateGen(new Date())], placeholder: '起止日期', label: '起止日期', clearable: true, range: true,
      },
    ])

    const resolveStatusText = computed(() => status => statusOptions.value.find(x => x.value === status)?.label)

    const fetchList = async () => {
      loading.value = true
      try {
        const {
          data: {
            data, status, message, total,
          },
        } = await getPage({
          page: page.value,
          pageSize: pageSize.value,
          ...paramsQuery,
        })
        loading.value = false
        if (status === 200) {
          /* 第一遍遍历先获取aliOssClient的Map并实例化AliOSS对象 */
          data.forEach(item => {
            if (!aliOssClientMap[item.aliBucket] && item.aliBucket) {
              aliOssClientMap[item.aliBucket] = new AliOSS(item.aliBucket)
            }
          })

          /* 第二遍遍历异步阻塞代码去获取aliOssClientMap的OSS client */
          const aliOssClientMapKeys = Object.keys(aliOssClientMap)
          if (!aliOssClientMapKeys.length) {
            pageLength.value = total
            pageData.value = data

            return
          }
          aliOssClientMapKeys.forEach(async (item, index) => {
            if (!aliOssClientMapKeys.includes(item.aliBucket)) {
              try {
                await aliOssClientMap[item].fetchOSS()
              } catch (err) {
                console.log(err)
              }
            }

            if (index === aliOssClientMapKeys.length - 1) {
              pageLength.value = total
              pageData.value = data
            }
          })

          // pageLength.value = total
          // pageData.value = data
        } else {
          modalFail(message)
        }
      } catch (err) {
        modalFail(err.response.data.message)
      }
    }

    // 搜索
    const search = params => {
      page.value = 1
      pageSize.value = 10
      Object.assign(paramsQuery, params)
      fetchList()
    }

    // 跳转详情
    const handleJump = item => {
      showDetail.value = true
      currItem.value = item
    }

    // 重试
    function handleRetry(item) {
      modalEnquireTitle('提示', `视频${item.title}将重新上传，请确认。`).then(async result => {
        if (result.isConfirmed) {
          try {
            const { data: { status, message } } = await retryUpload(item.id)

            if (status === 200) {
              toastSuccess('上传成功！')
              fetchList()
            } else {
              toastFail(message)
            }
          } catch (err) {
            modalFail(err.response.data.message)
          }
        }
      })
    }

    const handleUpload = () => {
      showDetail.value = true
      currItem.value = {}
    }

    function handleSizeChange(val) {
      page.value = 1
      pageSize.value = val
      fetchList()
    }
    function handleCurrentChange(val) {
      page.value = val
      fetchList()
    }

    fetchList()

    return {
      loading,
      pageLength,
      page,
      pageSize,
      searchConfig,
      statusOptions,
      tableColumns: reactive(tableCols),
      resolveStatusText,
      showDetail,
      currItem,
      pageData,
      defo,
      aliOssClientMap,

      resolveTime,
      search,
      handleUpload,
      handleJump,
      handleRetry,
      fetchList,
      handleSizeChange,
      handleCurrentChange,
    }
  },
})
</script>

<template>
  <v-card>
    <div style="padding: 20px">
      <XwSearch
        :form-config="searchConfig"
        @search="search"
      ></XwSearch>
    </div>
    <div style="padding: 0 20px 20px 20px">
      <v-btn
        color="primary"
        @click="handleUpload"
      >
        上传
      </v-btn>
    </div>
    <!-- table -->
    <div style="padding: 0 20px">
      <v-progress-linear
        v-if="loading"
        indeterminate
      />
      <XwTable
        ref="tableRef"
        :columns="tableColumns"
        :rows="pageData"
      >
        <!-- 视频 -->
        <template #video="{row:item}">
          <div
            class="video-body mt8"
            style="width: 100px"
          >
            <a
              v-if="item.videoPath && item.aliBucket"
              :href="aliOssClientMap[item.aliBucket] && aliOssClientMap[item.aliBucket].fetchDownloadLink(item.videoPath)"
              style="text-decoration: none;"
              target="_blank"
            >
              <v-img
                :src="item.imgPath && aliOssClientMap[item.aliBucket]? aliOssClientMap[item.aliBucket].fetchDownloadLink(item.imgPath): defo"
                height="50"
                width="89"
                style="background: #eee; border-radius: 4px;"
              />
            </a>
            <img
              v-else
              :src="defo"
              style="width: 89px;height: 50px"
            >
            <div style="height: 22px">
              <a
                v-if="item.videoPath && item.aliBucket"
                :href="aliOssClientMap[item.aliBucket] && aliOssClientMap[item.aliBucket].fetchDownloadLink(item.videoPath)"
                style="text-decoration: none;"
                target="_blank"
              >{{ item.title }}</a>
              <span v-else>{{ item.title }}</span>
            </div>
          </div>
        </template>
        <!-- 时间 -->
        <template #time="{row: item}">
          <div class="d-flex flex-column">
            <div
              class="colTime font-weight-medium text--primary text-truncate"
            >
              创建时间：{{ item.createdAt?resolveTime(item.createdAt):'-' }}
            </div>
            <div
              class="colTime font-weight-medium text--primary text-truncate"
            >
              上传时间：{{ item.uploadAt ? resolveTime(item.uploadAt) : '-' }}
            </div>
            <div
              class="colTime font-weight-medium text--primary text-truncate"
            >
              预设发布时间：{{ item.releaseAt?resolveTime(item.releaseAt):'-' }}
            </div>
          </div>
        </template>
        <template #status="{ row: item }">
          <XwStatus
            :status="item.status"
            :text="resolveStatusText(item.status)"
          ></XwStatus>
        </template>
        <!-- 操作列slot -->
        <template #actions="{row: item}">
          <a
            v-if="[6].includes(item.status)"
            class="ml-4"
            @click="handleRetry(item)"
          >
            重试
          </a>
          <a
            @click="handleJump(item)"
          >
            设置/详情
          </a>
        </template>
      </Xwtable>
    </div>
    <div
      v-if="pageLength"
      style="padding: 10px 20px;"
    >
      <new-pagination
        :page-index="page"
        :page-size="pageSize"
        :total="pageLength"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <Detail
      v-if="showDetail"
      :is-add-new-sidebar-active.sync="showDetail"
      :curr-item="currItem"
      :can-edit="[0, 1, 6].includes(currItem.status) || !currItem.status"
      @success="fetchList"
    ></Detail>
  </v-card>
</template>
